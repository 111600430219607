import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { User } from '../interfaces/user.types';
import { LoginService } from '../services/login.service';
import { UrlRedirectService } from '../services/url-redirect.service';
import { AuthInfo } from './auth-info';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly urlRedirectService = inject(UrlRedirectService);
  static UNKNOWN_USER = new AuthInfo(null);
  static UNIKNOW_ROLES = {};
  static UNIKNOW_SUB_ROLES = {};
  static UNIKNOW_LOGINAS = undefined;

  authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthService.UNKNOWN_USER);
  loginAs$: BehaviorSubject<any> = new BehaviorSubject<any>(AuthService.UNIKNOW_LOGINAS);
  roles$: BehaviorSubject<any> = new BehaviorSubject<any>(AuthService.UNIKNOW_ROLES);
  subRoles$: BehaviorSubject<any> = new BehaviorSubject<any>(AuthService.UNIKNOW_SUB_ROLES);
  private userInfo$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private loginService: LoginService,
    private location: Location,
  ) {
    this.initAuth();
  }

  initAuth(): boolean {
    // const hasUid = localStorage.getItem('uid') || '';
    const hasUid = localStorage.getItem('uid') || '';
    if (hasUid !== '') {
      // Set uid to AuthInfo
      const u = new AuthInfo(hasUid);
      this.authInfo$.next(u);
      // Get User from backend
      this.getUser({ uid: hasUid }).then(() => {
        // Configure Dashboard roles (login as)
        const loginAs = localStorage.getItem('loginAs') || '';
        if (loginAs !== '') {
          this.loginAs$.next(loginAs);
        }

        return true;
      });
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  login(user: { uid: string }) {
    return new Promise((resolve) => {
      const authInfo = new AuthInfo(user.uid);
      this.authInfo$.next(authInfo);
      this.getUser(user).then((res) => {
        resolve(res);
      });
    });
  }

  logout() {
    if (this.isAcceptedPolicy()) {
      localStorage.clear();
      localStorage.setItem('acceptedPolicy', 'true');
    } else {
      localStorage.clear();
    }
    this.auth.signOut().then(() => {
      this.authInfo$.next(AuthService.UNKNOWN_USER);
      this.urlRedirectService.redirectUrl.set(null);
      this.router.navigate(['/login']);
    });
  }

  isAcceptedPolicy(): boolean {
    const policyStatus = localStorage.getItem('acceptedPolicy') || 'notAccepted';
    return policyStatus === 'notAccepted' ? false : true;
  }

  getRoles() {
    return this.roles$.value;
  }

  getSubRoles() {
    return this.subRoles$.value;
  }

  getIsAdmin() {
    return this.roles$.value.isAdmin === true;
  }

  getDashboardRoles() {
    if (this.roles$.value.isExpert === true) {
      return 'expert';
    } else if (this.roles$.value.isGuardian === true) {
      return 'guardian';
    }
  }

  getUser(user: { uid: string }) {
    return new Promise((resolve) => {
      this.loginService.login(user.uid).subscribe((u) => {
        this.setRoles(u);
        this.setSubRoles(u);
        this.userInfo$.next(u);
        resolve(this.setLocalStorage(u, user));
      });
    });
  }

  getSavedUserInfo(): BehaviorSubject<User> {
    return this.userInfo$;
  }

  setRoles(user) {
    for (const userKey in user.roles) {
      if (user.roles.hasOwnProperty(userKey)) {
        localStorage.setItem(userKey, user.roles[userKey]);
      }
    }
    this.roles$.next(user.roles);
  }

  setSubRoles(user) {
    for (const userKey in user.additionalRoles) {
      if (user.additionalRoles.hasOwnProperty(userKey)) {
        localStorage.setItem(userKey, user.additionalRoles[userKey]);
      }
    }
    this.subRoles$.next(user.additionalRoles);
  }

  isLoggedInAs(param: string): boolean {
    return param === localStorage.getItem('loginAs');
  }

  isEnabledForUsers(params = ['isAdmin']) {
    const roles = this.getRoles();
    let returnValue = false;
    const loginAs = localStorage.getItem('loginAs') || '';
    for (let i = 0; i < params.length; i++) {
      if (roles.hasOwnProperty(params[i])) {
        const tmp = params[i];
        if (roles[tmp] && loginAs === tmp) {
          returnValue = true;
          break;
        }
      }
    }
    return returnValue;
  }

  async isEnabledForUsers$(params = ['isAdmin']): Promise<boolean> {
    return new Promise(async (resolve) => {
      const rolesExist = await this.checkIfRolesExist();
      if (rolesExist) {
        const isEnabled = this.isEnabledForUsers(params);
        if (isEnabled) {
          return resolve(true);
        }
      }
      return resolve(false);
    });
  }

  async checkIfRolesExist(): Promise<boolean> {
    return new Promise((resolve) => {
      setInterval(() => {
        const roles = this.getRoles();
        if (Object.keys(roles).length !== 0) {
          return resolve(true);
        }
      }, 200);
    });
  }

  isEnabledForSubRoles(params: string[]) {
    if (!params) {
      return false;
    }
    const subRoles = this.getSubRoles();
    if (!subRoles) {
      return false;
    }
    let returnValue = false;
    for (let i = 0; i < params.length; i++) {
      if (subRoles.hasOwnProperty(params[i])) {
        const tmp = params[i];
        if (subRoles[tmp]) {
          returnValue = true;
          break;
        }
      }
    }
    return returnValue;
  }

  backPage() {
    this.location.back();
  }

  setLocalStorage(u: Object, user: { uid: string; email?: string }) {
    localStorage.setItem('name', u['name'] || '');
    localStorage.setItem('surname', u['surname'] || '');
    localStorage.setItem('id', u['_id']);
    localStorage.setItem('uid', user.uid);
    localStorage.setItem('uid', user.uid);
    localStorage.setItem('idGuardian', u['guardian']);
    localStorage.setItem('idInvestor', u['investorId']);
    localStorage.setItem('idOwner', u['ownerId']);
    if (u['expert']) {
      localStorage.setItem('idExpert', u['expert']._id);
    }
    localStorage.setItem('email', u['email']);
    if (u['avatar']) {
      localStorage.setItem('avatar', u['avatar']);
    }
    return true;
  }
}
