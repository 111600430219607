// // the file contents for the current environment will overwrite these during build.
// // the build system defaults to the dev environment which uses `environment.ts`, but if you do
// // `ng build --env=prod` then `environment.prod.ts` will be used instead.
// // the list of which env maps to which file can be found in `.angular-cli.json`.
//
// export const environment = {
//   appBranches: {
//     accountancy: '5a9a0aec6ae6ec075406e4a3',
//     invest: 'Cj5TIX1o3AaeJwFvlUV4',
//   },
//   bpcAccount: {
//     userId: '5aba6edf13bcb20004c8a59e',
//     guardianId: '5aba6f0e13bcb20004c8a59f',
//     expertId: '5aba6f2813bcb20004c8a5a0',
//     clientId: '5aba6f6213bcb20004c8a5a1'
//   },
//   production: false,
//   url: 'https://ec2-34-242-223-143.eu-west-1.compute.amazonaws.com:8020//api/',
//   firebaseUrl: 'https://us-central1-bpc-test.cloudfunctions.net/',
//   firebase: {
//     apiKey: 'AIzaSyCR6NlXA7_dTP5dx1adlaJ5T-7pgf8ayS0',
//     authDomain: 'bpc-test.firebaseapp.com',
//     databaseURL: 'https://bpc-test.firebaseio.com',
//     projectId: 'bpc-test',
//     storageBucket: 'bpc-test.appspot.com',
//     messagingSenderId: '251603921683'
//   }
// };
// // appBranches: {
// //   accountancy: '5b6709ca4b42e50004056e58',
// //   invest: '5a94125574c6f700042bb0ec',
// // },
// // bpcAccount: {
// //   userId: '5aca0365d97afc0004421cc8',
// //   guardianId: '5aca049dd97afc0004421cca',
// //   expertId: '5aca0480d97afc0004421cc9',
// //   clientId: '5aca051cd97afc0004421ccb'
// // },
// // production: false,
// // url: 'https://ec2-34-242-223-143.eu-west-1.compute.amazonaws.com:8020//api/',
// // firebaseUrl: 'https://us-central1-bpc-prod.cloudfunctions.net/',
// // firebase: {
// //   apiKey: 'AIzaSyDko-mZj8TIXlPhMSLeeIPXVfaZrrBRTYI',
// //   authDomain: 'bpc-prod.firebaseapp.com',
// //   databaseURL: 'https://bpc-prod.firebaseio.com',
// //   projectId: 'bpc-prod',
// //   storageBucket: 'bpc-prod.appspot.com',
// //   messagingSenderId: '201966676740'
// // }

export const environment = {
  accountancyMail: 'ksiegowosc@bpc.pl',
  contestMail: 'konkurs@bpc.pl',
  debtMail: 'windykacja@bpc.pl',
  portalMail: 'portal@bpc.pl',
  appBranches: {
    accountancy: '5a9a0aec6ae6ec075406e4a3',
    invest: 'Cj5TIX1o3AaeJwFvlUV4',
  },
  bpcAccount: {
    userId: '5aba6edf13bcb20004c8a59e',
    guardianId: '5aba6f0e13bcb20004c8a59f',
    expertId: '5aba6f2813bcb20004c8a5a0',
    clientId: '5aba6f6213bcb20004c8a5a1',
  },
  production: true,
  sentry: false,
  url: 'https://8dib8ydzia.execute-api.eu-central-1.amazonaws.com/api/',
  firebaseUrl: 'https://us-central1-bpc-test.cloudfunctions.net/',
  firebase: {
    apiKey: 'AIzaSyCR6NlXA7_dTP5dx1adlaJ5T-7pgf8ayS0',
    authDomain: 'bpc-test.firebaseapp.com',
    databaseURL: 'https://bpc-test.firebaseio.com',
    projectId: 'bpc-test',
    storageBucket: 'bpc-test.appspot.com',
    messagingSenderId: '251603921683',
    appId: '1:251603921683:web:1dc97829e709960ea648e6',
    measurementId: 'G-T27LP88K85',
  },
};
