import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.reAuthenticate()
            .then(() => {
              return next.handle(request);
            })
            .catch(() => {
              localStorage.clear();
              localStorage.setItem('returnPage', window.location.pathname);
              window.location.replace('/login');
            });
        }

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        console.error(error);
        return throwError(error);
      }),
    );
  }

  reAuthenticate(): Promise<any> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(
          (idToken) => {
            localStorage.setItem('authToken', idToken);
            resolve(true);
          },
          () => {
            reject();
          },
        );
    });
  }
}
