import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = from(this.getToken()).pipe(
      map((token) => {
        return req.clone({
          setHeaders: {
            Authorization: `${token}`,
          },
        });
      }),
    );
    const response = request.pipe(switchMap((r) => next.handle(r)));

    return response;
  }

  getToken() {
    const token = localStorage.getItem('authToken');
    return Promise.resolve(token);
  }
}
