import { CanDeactivate } from '@angular/router';

export class DirtyFormGuard<T> implements CanDeactivate<T> {
  canDeactivate(component: T): boolean {
    if (component && component['isDirty']?.resetForm === true) {
      return true;
    } else if (component && component['isDirty']?.dirty === true) {
      return confirm('Posiadasz niezapisane zmiany w formularzu, czy na pewno chcesz wyjść?');
    }
    return true;
  }
}
